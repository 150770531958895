import LocalizedStrings from "react-localization";

export const LOCALE = new LocalizedStrings({
  mk: {
    product_price_new: "Цена на производ",
    prod_description: "Подетален опис на производот",
    create_offer_success: "Понудата е успешно поднесена",
    create_catalog_success: "Каталогот е успешно креиран",
    dashboard: "Работна табла",
    evaluation_documentation: "Документација од јавно отвoрање и евалуација",
    document_details: "Преглед на договори",
    tenders_active: "Активни огласи",
    certificate_name: "Име на сертификат",
    certificate_issuer: "Издавач",
    valid_from: "Валиден од",
    valid_to: "Валиден до",
    chosenCertificate: "Избран сертификат:",
    removeCertificate: "Отстрани сертификат",
    users: "Корисници",
    attributes: "Карактеристики",
    min_requirements: "Минимални карактеристики",
    deleted: "Избришан",
    my_catalog: "Мој Каталог",
    chooseCertificate: "Изберете сертификат",
    certificate: "сертификат",
    choose: "Изберете",
    generate_offer: "Креирај понуда",
    my_offer: "Мои понуди",
    changed_budget_doc: "Одлука за промена на проценета вредност",
    no_offers_message: "Нема понудувачи за избраната набавка",
    unauthorized: "Немате привилегии за пристап",
    send_request: "Испрати барање",
    message: "Порака",
    request_type: "Тип на барање",
    request_brand: "Предлог производител",
    request_product_type: "Предлог тип на производ",
    request_new_attributes: "Предлог карактеристики",
    request_category: "Предлог категорија",
    success_sent_request: "Успешно испратено барање",
    request_type_required: "Задолжително внесете тип на барање",
    catalog_product_request_by_ca:
      "Доколку во каталогот недостасува одреден производ кој сакате да го додадете во вашата набавка, потребно е да го испратите вашето барање за додавање на производ до e-pazar@bjn.gov.mk",
    offerExisisInQueue: "Ставката е веќе селектирана",
    terminate_offers_reject_reason:
      "Наведете причина за раскинување на договорот за секоја од ставките.",
    inactive_products: "Неактивни производи",
    reject_reason_validation_message:
      "Полето за образложение не смее да биде празно",
    min_required_attributes:
      "За да го зачувате производот потребно е да внесете минимум две карактеристики",
    publish_attributes_validation:
      "За да го објавите производот потребно е да имате внесено минимум две карактеристики",
    choose_commission_member:
      "Изберете член од листата на членови на комисија за јавни набавки",
    publish_description_validation:
      "Полето за опис го надминува дозволениот лимит од 50 карактери. Ажурирајте ја вредноста за да го објавите производот",
    attributes_for_product_type_request:
      "Предлог карактеристики за тип на производ ",
    request_attibutes_for_product_type:
      "Во пораката задолжително внесете и за кој тип на производ сакате да бидат внесени карактеристиките",
    attributes_error_message:
      "За да го додадете производот во вашиот каталог потребно е за истиот да има внесено минимум две карактеристики. Доколку за овој производ недостасуваат карактеристики или пак предефинирани вредности за прикажаните карактеристики испратете предлог до e-pazar@bjn.gov.mk",
    no_product_type:
      "Додадете го производителот во полето означено за производител. Доколку сакате одреден производител, категорја или тип на производ да биде достапен на е-Пазарот потребно е да го испратете вашето барање со предлог производител, категорија/тип на производ и соодветни карактеристики до e-pazar@bjn.gov.mk.",
    ddv: "ДДВ",
    appeal: "Жалби",
    appeal_documentation: "Документација од жалба",
    automatic_import: "Автоматско вчитување",
    manual_import: "Мануелно вчитување",
    procedure_type: "Вид на постапка",
    decision_pdf_generate: "Генерирај одлука",
    procedure_status: "Статус на постапката",
    contract_status: "Статус на договор",
    constract_sent_to_eo: "Договорот е испратен до економскиот оператор",
    reject_decision_doc: "Одлука за поништување",
    proffesional_docs: "Способност за вршење професионална дејност",
    special_docs_text: "Посебните услови за вршење на дејноста",
    appeal_status: "Статус на жалба",
    create_appeal: "Креирај жалба",
    create_eAppeal: "Креирај е-Жалба",
    response: "Одговор",
    tender_snapshot_reject_reason_message:
      "Набавката е вратена на корекција од страна на БЈН. За да ја објавите набавката потребно е да ги направите наведените промени и повторно да ја испратите на проверка. Образложение:",
    select_approval_response:
      "Задолжително изберете одговор на предлогот за набавка. Доколку го одбивате предлогот, полето за образложение не смее да биде празнo.",
    tender_approval_success: "Успешно испратен одговор",
    tender_snapshot_approved: "Набавката е одобрена за објава",
    tender_snapshot_rejected: "Корекција на набавка",
    bjn_admin: "БЈН админ",
    approve_publish_tender:
      "Набавката може да се објави од страна на договорниот орган",
    reject_publish_tender:
      "Има пропусти во спецификацијата на набавката. Набавката се враќа на корекција од страна на договорниот орган.",
    appeal_reason_required: "Основи за жалба е задолжително поле",
    appeal_response_required: "Детални информации е задолжително поле",
    appeal_documents_required: "Внесете документи",
    reject_decision_reason: "Внесете образложение за поништување на постапката",
    appeal_reason_and_documents_req:
      "Задолжително внесете основи за жалба и докумети",
    appeal_decision_and_documents_req:
      "Задолжително внесете тип на одлука и докумети",
    appeal_response_and_documents_req:
      "Задолжително внесете одговор по жалба и докумети",
    appeal_response: "Одговор по жалба",
    edit_appeal: "Едитирај жалба",
    has_appeal: "Обжалена постапка",
    created_appeal: "Креирана жалба",
    appeal_document_upload_success: "Успешно прикачени документи",
    edit_requrement_restriction: "Забранета акција",
    tender_for_correction: "Набавки за корекција",
    send_tender_for_approval_success:
      "Успешно испратено барање за креирање на набавка",
    sign_documents: "Документи за потпис",
    order_statement: "Обврска за нарачување на целата количина",
    order_statement_doc: "Изјава за обврска за нарачување на целата количина",
    order_number: "Реден број",
    order_number_for_reqirement: "Реден број",
    list_error_msg: "Изберете минимум една ставка за повторна евалуација",
    awaiting_appeal_response: "Се чека одлука по жалба",
    appeal_eo_additional_documents: "Побарај дополнување од ЕО",
    appeal_ca_additional_documents: "Побарај дополнување од ДО",
    catalog_product_deactivation_reason: "Причина за деактивација",
    deactivate_product_success: "Успешно деактивиран производ",
    deactivate_product: "Деактивација на производ",
    deactivated_products: "Деактивирани производи",
    success_signing_documents: "Договорите се успешно потпишани",
    deactivation_date: "Датум на деактивација",
    signed_contract_date: "Датум на склучување на договор",
    procurement_carrier: "Носител на набавката",
    deactivated: "Деактивиран",
    signed_contracts: "Склучени договори",
    contract_date: "Датум на договор",
    contract_value: "Вредност на договорот со ДДВ",
    contract_info_message:
      "Договорот ќе биде испратен до економскиот оператор по истекување на рокот за жалба (пет дена по неговото потпишување).",
    accepted_terms_and_conditions:
      "Прифатените правила и услови за користење на е-Пазар може да ги прегледате тука",
    user: "Корисник",
    add_comment_required: "Додадете коментар",
    exising_measure_unit: "Постоечка мерна единица",
    new_product_type: "Нов тип на производ",
    existing_product_type: "Постоечки тип на производ",
    published_products: "Објавени производи",
    date_updated: "Датум на ажурирање",
    deactivate: "Деактивирај",
    product_id: "ИД",
    new_measure_unit: "Нова мерна единица",
    attributes_required:
      "Наведете минимум две вредности за селектираната карактеристика",
    attribute_is_mandatory: "Задолжителна",
    sign_contracts: "Потпиши договори",
    sign_contracts_success: "Успешно потпишани договори",
    sign_contract_success: "Успешно потпишан документ",
    eo_order_non_signed_contract:
      "Нарачката може да биде извршена откако економскиот оператор ќе потпише договор",
    terminate_non_signed_contract:
      "Договорот не може да биде раскинат бидејќи не е потпишан од страна на економксиот оператор",
    contracts: "Договори",
    signed_by_ca: "Договор потпишан од договорен орган",
    signed_by_eo: "Договор потпишан од двете страни",
    generated_contract: "Генериран договор",
    signedDocuments: "Потпишани",
    notSignedDocuments: "Непотпишани",
    invalidDocuments: "Невалидни",
    allContractDocuments: "Сите",
    eo_contact_person: "Лице за контакт",
    contract_document: "Договор",
    name_and_surname: "Име и презиме",
    tendersWithoutDecision:
      "Број на набавки од годишен план за кои нема одлука",
    tender_contract: "Договор за јавна набавка",
    tender_document_preview_error: "Грешка при генерирање на документ",
    attribute_value_length: "Вредноста не смее да надминува 200 карактери",
    send_additional_documents: "Испрати известување",
    appeal_additional_documents: "Побарај дополнување на документи",
    add_document_end_date:
      "Изберете датум како краен рок за прикачување на документите",
    description: "Опис",
    order_sign_success: "Документот е успешно потпишан",
    eo_additional_doc: "Дополнување на документ од ЕО",
    ca_additional_doc: "Дополнување на документ од ДО",
    comment: "Коментар",
    sign: "Потпиши",
    statemеnt_conflict_of_interest: "Изјава за судир на интереси",
    order_doc: "Документ од нарачка",
    withdrawn_appeal: "Повлечена жалба",
    warning: "Напомена",
    confirm: "Потврди",
    create_appeal_for: "Креирај жалба за постапка ",
    appeal_reason_tooltip:
      "Во овој дел се внесуваат одредбите од законот кои сметате дека се повредени.",
    appeal_elaborate: "Во ова поле се прикачува жалбата, дигитално потпишана.",
    payment_proof_elaborate:
      "Во ова поле прикачете документ за извршена уплата",
    appeal_create_success: "Жалбата е успешно креирана",
    appeal_extensions: "Дозволена екстензија е: .pdf,.doc,.docx,.jpg;",
    send: "Испрати",
    procedure_details: "Податоци за постапката",
    resource: "Извор на средства",
    procedure_date: "Датум на постапка",
    appeal_date: "Датум на поднесување",
    additional_information: "Дополнителни информации",
    view_appeals: "Прегледај жалби",
    view_contract: "Прегледај договор",
    sign_contract: "Потпиши договор",
    download_contract: "Преземи договор",
    appeal_reason: "Основи за жалба",
    document_type: "Тип на документ",
    document: "Документ",
    insert_doc: "Прикачи",
    payment_proof: "Доказ за извршена уплата",
    additional: "Прилози",
    e_Appeals: "Е-Жалби",
    view_appeal: "Прегледај жалба",
    review_drd: "Прегледај ДРД",
    withdrawn_requirement: "Поништена ставка",
    contract_termination: "Раскинување на договор",
    terminate_contract: "Раскини договор / дел",
    terminate: "Раскини",
    contract: "договор",
    terminate_contract_for_requirements: "Раскинување на договор за делови",
    terminated_contract: "Раскинат договор",
    terminated_contracts: "Раскинати договори",
    terminated_contract_additional_documents:
      "Изјава за раскинување на договор / дел",
    terminated_offer_contracts: "Раскинат договор / дел",
    create_order_to_terminate_contract:
      "Договорот може да биде раскинат само доколку нема одговор на нарачка или нарачката е несоодветно испорачана",
    terminate_contract_error_message:
      "За ставката има активна нарачка. Испратете одговор на нарачката за да може да го раскинете договорот.",
    terminate_contract_warning:
      "Дали сте сигурни дека сакате да го раскинете договорот за следните делови? Откако ќе го раскинете договорот, може да спроведете нова набавка за овие делови.",
    terminate_contract_reason_message:
      "Наведете ги причините за раскинување на договорот за секоја дел соодветно.",
    terminate_contract_info:
      "При раскинување на договорот се генерира документ во кој се наведени деловите за кои се раскинува договорот. Потребно е да изберете валиден сертификат за потпис на документот. Документот е достапен во досието на постапката. По раскинување на договорот, економските оператори со кои е раскинат договорот ќе бидат исклучени од постапките кои ги спроведува Вашиот договорен орган во наредните 12 месеци.",
    error_signing_terminated_contract: "Грешка при потпишување на документот",
    offer_warning:
      "Доколку понудувачот не ги испорача стоките или истите не се согласно избраната техничка спецификација, договорниот орган има право да го раскине договорот. По раскинување на договорот ќе бидете исклучени од постапките за јавни набавки кај тој договорен орган во период од 12 месеци од денот на раскинување на договорот.",
    terminate_contract_reason: "Причина за раскинување на договорот",
    quantity_greater_than_zero: "Количината мора да биде поголема од 0",
    generate_contract_termination_document:
      "Генерирање на изјавата за раскинување на договор...",
    sign_contract_termination_document: "Потпишување на изјавата...",
    pending_order: "Нарачката се испраќа...",
    accepted_requirement: "Избрана ставка",
    chosen_offer: "Избрана понуда",
    terminate_and: "Раскини и",
    tender_order_delivery_address:
      "Стоката да се испорача на наведената адреса: ",
    no_offers_req: "Нема понуди за ставката",
    is_partial_procurement: "Дали набавката ќе се спроведе преку една објава?",
    existingPlan: "Планот е веќе искористен во набавка ",
    plan_used_in_non_partial_procurement: "Планот е веќе искористен",
    budget_limit_message: "Вредноста не смее да надминува ",
    budget_after_termination_error:
      "Проценетата вредност не смее да надминува ",
    additional_docs_error:
      "Доколку имате селектирано прикачување на дополнително документи, текстуалното поле е задолжително",
    activeTenderForAnnualPlan:
      "Има активна постапка за овој предмет на јавна набавка",
    no_valid_certificates: "Не се пронајдени валидни сертификати",
    partial_procurement_message:
      "Доколку планирате да спроведете една набавка и да ја искористите целата проценета вредност го селектирате копчето ДА. Доколку сакате да спроведете повеќе сукцесивни набавки, потребно е да го селектирате копчето НЕ и да ја наведете проценетата вредност на конкретната поединечна набавка.",
    appeal_details: "Детали за жалба",
    publish_selected: "Објави ги селектираните",
    unpublish_selected: "Повлечи ги селектираните",
    search_eo_values: "Внесете минимум 3 карактери",
    superadmin: "Администратор",
    product_exists: "Производот е веќе додаден во кошничка",
    change_budget_value:
      "Доколку сакате да ја промените проценетата вредност внесета ја новата вредност во полето: ",
    new_budget_value: "Нова проценета вредност",
    save_budget: "Проценетата вредност е успешно променета",
    delete_document_confirm:
      "Дали сте сигурни дека сакате да го избришете документот?",
    delete_appeal_confirm:
      "Дали сте сигурни дека сакате да ја повлечете жалбата?",
    appeal_delete_success: "Жалбата е успешно избришана",
    configuration: "Конфигурација",
    conf_end_date: "Краен рок за достава на понуди во денови",
    appeal_create_date: "Датум на жалба",
    send_appeal_response_one: "Поднеси одговор",
    send_appeal_response_two: "по жалба",

    phone_number: "Телефонски број",
    embg: "ЕМБГ или 13 цифри",
    mobile_number: "Мобилен телефон",
    profile_documents: "Документи на профил",
    doc_message:
      "Економскиот оператор е одговорен за ажурирање на документите преку овој панел, како и за валидноста на прикачените документи!",
    upload_drd: "Прикачи ДРД",
    choose_documents: "Изберете документи",
    choose_document: "Изберете документ",
    edit_doc: "Ажурирај документи",
    edit_drd: "Преземи ДРД",
    download_appeal_doc: "Преземи",
    download: "Преземи",
    choose_appeal_decision: "Ве молиме изберете тип на одлука",
    offer_statement: "Изјава за сериозност на понудата",
    offer_statement_text: "Текст за изјава на сериозност на понудата",
    no_drd_message: "Корисникот нема прикачено ДРД на системот на БЈН",
    commision_exists: "Корисникот е веќе додаден",
    commission_check: "Мора да внесете членови на комисија",
    add_president: "Ве молиме изберете претседател на комисија",
    choose_annual_plan: "Ве молиме изберете годишен план",
    annual_plan: "Набавка од годишен план",
    add_procurement_decision: "Прикачи одлука",
    annual_plan_budget: "Проценета вредност од план за јавни набавки",
    image_size_limit: "Големината на сликата не смее да надминува 1 MB",
    offers_for_tender_error: "Немате дадено понуда за ниедно барање",
    offer_invalid_price: "Вредноста не смее да биде празна",
    extensions_allowed:
      "Дозволени екстензии се: .doc, .txt, .zip, .pdf, .jpg, .jpeg, .gif, .odt, .rar, .xls, .slk, .xlw, .csv, .ppt, .pps, .docx, .xlsx, .pptx, .ods;",
    extension_allowed: "Дозволена екстензија е: .xlsx",
    extension_images: "Дозволена екстензија е: .jpg, .jpeg, .png",
    drd_file_length:
      "Големината на секој прикачен документ не смее да надмине 20 MB!",
    drd_document: "	Документ за регистрирана дејност",
    sign_and_create_offer: "поднеси понуда",
    appeal_date_error:
      "Датумот за доставување на документација може да биде максимум 5 дена од денес",
    appeal_required_date:
      "Задолжителен избор на датум за доставување дополнување",
    appeal_required_description:
      "Задолжително е прикачување на дополнителен документ за жалба или Опис",
    requested_additional_info: "Побарано е дополнување на документацијата",
    offer_for_terminated_contract_error:
      "Согласно со член 88 став 4 точка б исклучени сте од постапките за јавни набавки поради раскинат договор",
    offer_review_text: "Понуда во форма на е-Каталог",
    send_appeal: "Поднеси",
    appeal_reason_saved: "Успешно зачувани основи за жалба",
    appeal_sent_success: "Жалбата е успешно испратена",
    brand_update_success: "Промената е успешно зачувана",
    additional_info_success: "Успешно испратено барање",
    appeal_awaiting_response: "Се чека одговор",
    correction: "Исправка",
    estimated_value_no_ddv: "Вредност без вклучен ДДВ",
    record_decision:
      "Прикачена одлука од одговорно лице и известувања до понудувачите",
    order_quantity_price: "Цена со ДДВ",
    total_order_sum: "Вкупна сума за нарачка со ДДВ:",
    order_documents: "Документи од нарачки",
    procurement_decision: "Одлука за јавна набавка",
    budget_max_value:
      "Максималната дозволена цена на проценета вредност е 600.000 ден.",
    login_h1: "Биро за јавни набавки",
    login_h2: "Е-Пазар",
    login_signin: "Најави се",
    start_login: "Најава",
    profile: "Профил",
    reports: "Извештаи",
    download_report: "Преземи извештај",
    login_signup: "Регистрирај се",
    login_signup_link: "Немаш корисничка сметка?",
    login_forgot_password: "Промени лозинка",
    login_forgot_password_link: "Заборавена лозинка?",
    login_username: "Корисничко име",
    login_password: "Лозинка",
    login_email: "Е-пошта",
    login_firstname: "Име",
    login_lastname: "Презиме",
    login_confirm_password: "Потврди лозинка",
    login_submit: "Потврди",
    login_createuser_success: "Успешно регистриран корисник: ",
    login_fail: "Погрешно корисничко име или лозинка",
    required_field: " е задолжително поле",
    logout: "Одјави се",
    my_profile: "Мој профил",
    main_category_error:
      "Продукт тип не може да се додаде во главна категорија",

    uploaded_document: "Прикачен документ",
    evidence_number: "Евидентен број",
    case_date: "Датум",
    offer: "Понуда",
    ca_procurements: "Набавки",
    withdrawn_procurements: "Поништени набавки",
    catalog_price: "Цена од каталог",
    offer_price_alert:
      "Цената на производот можете да ја намалите доколку сакате да дадете попуст. Доколку пазарната цена на производот е зголемена, можете да ја ажурирате во каталогот.",
    send_withdraw_tender_one: "Испрати предлог",
    send_withdraw_tender_two: " за поништување",

    category_name: "Име на категорија",
    category_code: "Код на категорија",
    subcategory_name: "Име на подкатегорија",
    subcategory_code: "Код на подкатегорија",
    category_active: "Активна категорија",
    category_not_active: "Неактивна категорија",
    subcategory_active: "Активна подкатегорија",
    subcategory_not_active: "Неактивна подкатегорија",
    date_creted: "Датум на креирање",
    hierarchy_level: "Хиерархиско ниво",
    actions: "Акции",
    add_subcategory: "Додади подкатегорија",
    edit: "Промени",
    delete: "Избриши",
    details: "Детали",
    add_category: "Додади категорија",
    save: "Зачувај",
    choose_category: "Одберете категорија",
    edit_category: "Промени категорија",
    show_documents: "Прикажи",
    offer_documents: "Документи од понудата",
    no_orders_message: "Нема нарачки за избраната набавка",
    no_offers_for_requirement:
      "Нема преостанати понуди за селектираната ставка",
    cancel_contract: "Поништи договор",
    cancel_contract_message:
      "Дали сте сигурни дека сакате да го поништите договорот со економскиот оператор ${eo_name} за набавката со број ${tender_number}?",
    cancel_contract_reason: "Причина за поништување на договорот",
    cancel_contract_success: "Договорот е успешно поништен",
    canceled_contract: "Поништен договор",

    create_category_success: "Успешно креирана категорија",
    create_subcategory_success: "Успешно креирана подкатегорија",
    delete_category_success: "Успешно избришана категорија",
    edit_category_success: "Успешно изменета категорија",
    create_product_type_success: "Успешно креиран тип на производ",
    delete_product_type_success: "Успешно избришан тип на производ",
    edit_product_type_success: "Успешно изменет тип на производ",
    create_attribute_success: "Успешно креиран атрибут",
    delete_attribute_success: "Успешно избришан атрибут",
    edit_attribute_success: "Успешно изменет атрибут",
    search_product_type: "Пребарај тип на производ",
    create_product_success: "Успешно креиран производ",
    update_product_success: "Производот е успешно ажуриран",
    update_attribute_values_error: "Внесете соодветни вредности за ажурирање",
    delete_product_success: "Успешно избришан производ",
    attribute_success: "Успешно зачувани детали за барање",
    no_category: "Не се пронајдени подкатегории за избраната категорија",
    change_budget_document:
      "Прикачете документ за промена на проценета вредност",
    category_status: "Статус на категорија",

    eMarket: "Е-Пазар",
    footerTrade: "© 2020 - Биро за јавни набавки. Сите права задржани",
    disclaimer_part1:
      "Електронската платформа на е-Пазар што е составен дел на ЕСЈН е креирана со финансиска поддршка на Европската унија.",
    disclaimer_part2:
      "Нејзината содржина е единствена одговорност на Бирото за јавни набавки и не се одразува на ставовите на Европската унија.",
    footerText:
      " Корисниците се одговорни за веродостојноста и точноста на внесените податоци",
    electronicSystem: "Електронски систем за јавни набавки",
    testPeriod: "Пробен период",
    smallPP: "е-Пазар",
    e_catalogue: "Е-Каталог",
    activeTenders: "Активни набавки од мала вредност",
    finishedTenders: "Завршени набавки од мала вредност",
    all_tenders: "Набавки од мала вредност",
    decisions: "Одлуки",
    home: "Почетна",
    categories: "Категории",
    category: "Категорија",
    availability: "Достапност",
    average_price: "Просечна цена",
    min_required_quantity: "Минимална дозволена количина: 1",
    description_details: "Подетален опис: ",
    description_with_attributes: "Опис/Карактеристики",
    chosen_description_with_attributes: "Избрани Опис/Карактеристики",
    cpv: "CPV",
    measure_unit: "Мерна единица",
    attributes_and_measure_unit: "Карактеристики и мерна единица",
    contract_duration_months: "Времетраење на договорот во месеци",
    attributes_and_product_type: "Карактеристики и тип на производ",
    invalid_price_reason: "Невалидна цена",
    invalid_attribute: "Невалидни атрибути",
    add_to_basket: "Додади во кошничка",
    product_details: "ДЕТАЛИ ЗА ПРОИЗВОД",
    requirement_details: "Детали за ",
    close: "Затвори",
    search: "Пребарувај",
    active_tenders: "Активни набавки од мала вредност",
    finished_tenders: "Завршени набавки од мала вредност",
    tender_number: "Број",
    contracting_authority: "Договорен орган",
    tender_subject: "Предмет на набавката",
    tender_type: "Вид на набавката",
    start_date: "Датум на објава",
    end_date: "Краен рок",
    tenders_per_page: "Oгласи по страна:",
    economic_operator: "Економски оператор",
    subcategories: "Подкатегории",
    rows_per_page: "Редови по страница",
    chosen_category: "Одбрана категорија: ",
    delete_category: "Избриши категорија",
    choose_products_from_appeal: "Избери ставки",
    send_for_evaluation: "Испрати за евалуација",
    view_appeal_decision: "Прегледај одлука",
    finishEvaluation:
      "Доколку преминете на следниот чекор од евалуацијата нема да имате можност за исправка на избраните понуди за ставките.",
    withdraw_appeal: "Повлечи",

    create_tender: "Креирај набавка",
    publish_tender: "Објави набавка",
    basic_data: "Основни податоци за набавката",
    shopping_cart: "Купувачка кошничка",
    tender_name: "Предмет на набавката",
    tender_description: "Подетален опис на набавката",
    tender_administration: "Административни информации",
    tender_submission_date: "Краен рок за достава на понуди",
    tender_validity: "Период на важност на понудата во денови",
    tender_question_date: "Краен рок за поставување прашања",
    tender_answer_date: "Краен рок за одговор на прашања",
    tender_agreementExpectedDuration: "Период во месеци",
    annual_plans: "Одбери набавка од годишен план",
    estimated_value: "Проценета вредност",
    remaining_estimated_value: "Преостаната проценета вредност",
    estimated_value_decision: "Проценета вредност од одлука",
    estimated_value_first: "Проценета вредност од поединечна набавка",
    estimated_value_previous: "Проценета вредност од предходна набавка",
    public_value: "Јавна",
    type_of_tender: "Вид на набавката",
    contract_duration: "Времетраење на договорот",
    genetal_terms_of_contract: "Општи услови на договорот",
    eMarket_conditions: "Услови за користење на Е-пазар",
    product_description_length: "Должината на полето не смее да надминува ",
    marketplace_video_tutorial: "Видео упатства за е-Пазар",
    decision_contract_info_message:
      "По потпишување на договорот истиот ќе биде испратен до економскиот оператор по истекување на рокот за жалба, односно пет дена по потпишување на договорот.",
    characters: " карактери",
    eMarket_rules_conditions:
      "Правилата и условите за користење на е-Пазар може да ги прегледате тука",
    dashboard_rules:
      "Правилник за видовите набавки и начинот на вршење набавки од мала вредност преку Електронскиот пазар на набавки од мала вредност на Електронскиот систем за јавни набавки",
    eo_dashboard_text:
      "Е-Пазар претставува електронски систем за набавки од мала вредност на кој се нудат и набавуваат стоки и услуги до 10.000 евра",
    ca_dashboard_text:
      "E-Пазар претставува електронски систем за набавки од мала вредност кој им овозможува на договорните органи да спроведуваат набавки со проценета вредност до 10.000 евра",
    place_of_delivery: "Место на испорака ",
    delivery_date: "Датум на испорака",
    order_created: "Нарачката е успешно креирана",
    order_not_delivered: "Нарачката има делови кои биле несоодветно испорачани",
    order_delivered: "Нарачката е испратена",
    order_delivered_to_eo: "Нарачката е испратена до економскиот оператор",
    order_status: "Статус на нарачка",
    order_receieved: "Нарачката е примена од страна на Договорниот орган",
    order_not_receieved:
      "Нарачката не е примена од страна на Договорниот орган",
    payment_terms: "Услови на плаќање",
    tender_additional_documents: "Дополнително прикачени документи",
    tender_additional_document: "Дополнително прикачен документ",
    delete_document_confirmation_message:
      "Дали сте сигурни дека сакате да го избришете документот?",
    download_template: "Образец за преземање",
    create_announcement: "Креирај оглас",
    create_announcement_with_annual_plan: "Креирај оглас од год. план",
    guarantee_period: "Гарантен период",
    award_criteria: "Критериум за доделување на договор",
    product_quantity: "Количина",
    generate_pdf: "Прегледај PDF",
    add_documents: "Прикачи документи",
    upload_template: "Прикачи документ",
    files_count_validation: "Дозволено е да прикачите само еден документ",
    document_exists: "Веќе е внесен документ за овој тип на производ",
    upload_document_success: "Успешно прикачен документ",
    document_is_valid: "Прикачениот документ е валиден",
    import_success: "Произвоите се успешно вчитани",
    documents: "Документи",
    chosen_products: "Избрани производи",
    empty_cart: "Купувачката кошничка е празна",
    details_product: "Детали за производот",
    lowest_price: "Најниска цена",
    product_no_atributes: "Производот нема дополнителни карактеристики",
    get_catalog_price: "Преземи цена од каталог",
    total_price_limit: "Вкупната цена не смее да надминува ",
    upload_valid_drd:
      "Немате прикачено ДРД образец. Ве молиме прикачете валиден документ!",
    accept_decision_message:
      "Дали сте сигурни дека сакате да го прифатите извештајот од спроведена постапка и да донесете одлука за постапката?",
    reject_decision_message:
      "Дали сте сигурни дека сакате да ја донесете одлуката за поништување на постапката и да ги потпишете документите?",
    decision_accepted: "Одлуката е прифатена",
    decision_rejected: "Одлуката е отфрлена",
    withdraw_tender: "Поништи набавка",
    accept_and_sign_one: "Прифати и",
    accept_and_sign_two: "потпиши",
    withdraw_tender_success: "Набавката е поништена",
    withdraw_tender_decision: "Одлука за поништување на постапка",
    withdraw_tender_document: "Одлука за поништување на набавка",
    add_commission: "Избери комисија",
    delivery_requests: "Начин и рок на испорака/извршување на услугите",
    delivery_request: "Барање за испорака",
    sign_withdraw: "Потпиши и",
    withdraw_signed_tender: "поништи",
    payment_deadline: "Рок на плаќање",
    payment_deadline_text: "15 дена",
    new_budget_required_field: "Нова проценета вредност е задолжително поле",
    search_measure_unit: "Пребарај мерна единица",

    view_offers: "Прегледај понуди",
    choose_offer: "Избери понуда",
    view_decision: "Прегледај одлука",
    view_eo: "Прегледај EO",

    choose_products: "Избери производи",
    chooose_product: "Избери производ",
    search_products: "Пребарај производи",
    add_price: "Единечна цена",
    review_offer: "Прегледај понуда",

    order_delivery: "Испорака на нарачки",
    active_procurements: "Активни набавки",
    all_procurements: "Сите набавки",
    review_tender: "Преглед на набавката",

    product_type: "Тип на производ",
    add_product_type: "Додади тип на производ",
    product_type_name: "Име",
    product_type_code: "Код",
    add_attributes: "Додади карактеристики",
    delete_product_type: "Избриши тип на производ?",
    choose_product_type: "Избери тип на производ",
    chosen_product_type: "Избран тип на производ:",
    chosen_attribute: "Избрана карактеристика:",
    product_type_description: "Опис на тип на производ",
    edit_product_type: "Промени тип на производ",
    mandatory_attribute: "Задолжителени карактеристики",
    edit_attribute: "Промени карактеристики",
    invalid_brand: "Невалиден производител",
    attribute_name: "Име на карактеристика",
    delete_attribute: "Избриши карактеристика",
    custom_attribute: "Произволна вредност",
    add_value: "Додади вредност",
    no_products: "Нема производи за избраната категорија",
    attribute_value: "Вредност на карактеристика",
    attribute_values: "Вредности на карактеристика",
    value_exists: "Внесовте веќе постоечка вредност",
    attribute_required: "Задолжително поле",
    all_categories: "Сите категории",
    search_categories: "Пребарај категории",
    pdf_length: "Дозволен е еден документ",
    create_pdf_success: "Успешно додаден документ",
    update_pdf_success: "Успешно се преземени документите",
    char_number: "Полето мора да содржи 10 карактери",
    cancel: "Откажи",
    publishAll: "Oбјави ги сите",
    unpublishAll: "Повлечи ги сите",
    publish: "Објави",
    unpublish: "Повлечи",
    no_offers_tender: "Набавка без понуди",
    no_offers_tender_text:
      "За ниту една ставка од огласот не биле понудени производи/услуги. Набавката мора да биде поништена",

    requested_quantity: "Количинa за нарачка",
    start_evaluation: "Започни евалуација",
    start_evaluation_confirm:
      "Дали сте сигурни дека сакате да започнете евалуација за набавка со број: ",
    send_decision: "Испрати одлука",
    send_decision_message:
      "Дали сте сигурни дека сакате да ја испратите одлуката до одговорно лице?",
    remaining_quantity: "Преостаната количина",
    ordered_quantity: "Нарачана количина",
    respond_to_delivery: "Испрати одговор",
    respond_to_delivery_order: "Прием на нарачка",
    appeal_decision: "Решение по жалба",
    appeal_decision_text: "Одлука по жалба",
    appeal_commission: "Државна комисија за жалби",
    appeal_decision_success: "Одлуката е успешно испратена",
    decision_type: "Тип на одлука",
    delivery_response: "Одговорот е успешно испратен",
    date_delivery_request: "Барање за датум на испорака",
    deliver: "Достави",
    required_order_quantity: "Внесете количина за нарачка",
    max_order_quantity:
      "Количината за нарачка е поголема од понудената количина",
    order_history: "Историја на нарачки",

    accept_offer: "Прифати",
    reject_offer: "Отфрли",
    reject_offers: "Повлечи понуди",
    reject_offers_btn: "Повлечи",
    add_comment: "Додади коментар",
    reject_reason: "Причина за одбивање на понудата",

    create: "Креирај",
    info: "Информација",
    catalogMessage: "Немате креирано ваш каталог —",
    catalogMessage1: "со клик на копчето можете да креирате!",
    tenderMessage:
      "За дадените карактеристики се предложени вредности од избраниот производ и истите може да се променат!",
    tenderMessage1: "Набавката ќе биде објавена со следниве датуми!",
    catalog_name: "Име на каталог",
    catalog_description: "Опис на каталог",
    add_catalog: "Додади каталог",
    catalog_active: "Активен каталог",
    catalog_not_active: "Неактивен каталог",
    catalog_picture: "Слика",
    catalog_product_name: "Име на производ",
    catalog_product_price: "Цена",
    add_catalog_product: "Додади производ",
    delete_product: "Избриши производ",
    edit_product: "Промени производ",
    chosen_product: "Избран производ",
    chosen_attributes: "Избрани карактеристики",
    import_catalog_product: "Вчитај производи",
    no_products_added: "Немате додадено производи!",
    product_brand_rule:
      "За секој од производите потребно е да изберете минимум два производители или да го објавите известувањето без да одбирате производител",
    tender_product_requirement_att_error:
      "Секој од производите мора да има минимум 2 карактеристики!",
    product_requirement_empty_att_err:
      "Додадете дополнителни карактеристики за сите производи!",
    product_basic_data: "Основни податоци за производ",
    product_attribute: "Карактеристики на производ",
    product_attribute_value: "Детална спецификација",
    insert_attribute_value: "Внесете детална спецификација",
    choose_attribute_value: "Изберете вредност",
    product_name: "Име на производ",
    remove_from_cart: "Производот е отстранет од кошничка",
    product_description: "Опис на производ",
    product_price: "Единечна цена",
    product_price_ddv: "Единечна цена со ДДВ",
    product_price_with_ddv: "Цена со ддв",
    total_price_ddv: "Вкупно со ДДВ",
    product_price_without_ddv: "Единечна цена без ддв",
    product_image: "Додади слика/и",
    mandatory_attributes: "Задолжителни карактеристики",
    quantity_check: "Количина е задолжително поле",
    user_manuals: "Прирачници",
    user_manual_eo: "Прирачник за економски оператори",
    user_manual_ca: "Прирачник за договорни органи",
    attributes_tender_product_requirement:
      "Потребно е да внесете минимум две карактеристики за производот",

    brand_min_req:
      "Потребно е да изберете минимум два производители или да го објавите известувањето без да одбирате производител",
    not_mandatory_attribute: "Незадолжителни карактеристики",
    create_tender_error_msg:
      "За да креирате набавка потребно е да додадете производи",
    next: "Следно",
    back: "Претходно",
    go_back: "Назад",
    create_order: "Креирај нарачка",
    view_order: "Прегледај нарачка",
    order_response: "Се чека одговор",
    order: "Нарачај",
    networkError: "Проблем со конекција!",
    uploadImage:
      "Прикачени слики (селектираната слика ќе биде додадена прва во каталогот)",
    cа_name: "Назив на договорниот орган",
    cа_addres: "Адреса",
    cа_city: "Град",
    cа_postcode: "Поштенски код",
    cа_mail: "Интернет адреса",
    ca_contactName: "Лице за контакт",
    ca_contactMail: "Е-маил",
    ca_contactPhone: "Телефон/Факс",
    ca_tender_subject: "Предмет на набавката",
    invalid_email_address_format: "Форматот на меил адресата е невалиден",
    must_be_number: "Вредноста мора да биде број",
    no_offer: "Нема активни огласи!",
    offer_accept_success: "Избрана е понуда со минимална цена",
    offer_reject_success: "Понудата е одбиена!",
    offer_choosen_success: "Понудата е прифатена!",
    product_publish: "Објавени",
    product_unpublish: "Необјавени",
    products: "Производи",
    save_change: "Податоците се успешно зачувани",
    tender_status: "Статус на тендери",
    complete: "Завршени",
    notcomplete: "Тековни",
    choose_mesure_unit: "Одбери мерна единица",
    mesure_unit: "Мерна единица",
    create_measure_unit: "Додади мерна единица",
    create_measure_unit_success: "Успешно е додадена нова мерна единица",
    update_measure_unit: "Промени мерна единица",
    update_measure_unit_success: "Успешно променета мерна единица",
    delete_measure_unit: "Избриши мерна единица",
    delete_measure_unit_success: "Успешно избришана мерна единица",
    chosen_mesure_unit: "Одбрана мерна единица: ",
    no_measure_unit: "Не се пронајдени резултати",
    no_unpublished: "Нема необјавени производи",
    no_published: "Нема објавени производи",
    no_tenders: "Нема активни набавки",
    min_price: "Понудениот производ е со најниска цена",
    my_profile: "Мој профил",
    personal_data: "Лични податоци",
    publish_procurement: "Објава на набавка",
    documents_offer: "Документи за понуда",
    eo_occupation: "Документ за докажување на професионалната дејност ",
    upload_additional_documents: "Поднеси дополнителни документи",
    request_additional_documents: "Дополнителни документи",
    req_docs_by_ca: "Дополнителни документи побарани од стана на ДО",
    additional_documents_req_text:
      "Во овој дел наведете кои дополнителни докумнети се потребни",
    tender_to_be_published: "Набавката е испратена на објава",
    additional_document: "Дополнителен документ од понуда",
    no_offers_tooltip: "За оваа ставка нема поднесени понуди",
    no_requested_attributes: "Нема барани спецификации",
    no_offered_attributes: "Нема понудени спецификации",
    tenderCorrectionMessage: "Набавката е испратена на корекција",
    confirmCorrectedTender:
      "Набавката повторно ќе биде  испратена на техничка проверка. Доколку истата е соодветно корегирана ќе биде објавена за што ќе добиете порака.",
    publish_procurement_text:
      "Набавката ќе биде испратена на техничка проверка пред објава. Доколку спецификацијата на набавката е во ред истата ќе биде објавена. Ќе добиете порака во поштенското сандаче по успешното објавување или доколку треба да направите измени во набавката.",
    entity_delete_confirmation_text: "Дали сте сигурни",
    no_products_offer:
      "Нема производи во вашиот каталог соодветни на избраната ставка",
    chooose_order_product_delivery:
      "Избери производи за достава на делови од нарачка",
    create_tender_order: "Креирај нарачка по делови од набавка",
    total_order_delivered: "Нарачката е целосно доставена",
    order_delivery_error:
      "Нарачката има делови кои биле несоодветно испорачани",
    order_product_error:
      "Полето за количина за нарачка на секој производ не смее да биде празно или пак да има вредност поголема од преостанатата количина",
    no_orders_for_product: "Нема историја на нарачки за избраниот производ",
    no_proucts_error: "Не се поднесени понуди за производот",
    remove_tender: "Поништи набавка",
    decline_tender: "Поништи",
    chosen_tender: "Одбран тендер: ",
    delete_tender_success: "Набавката е успешно поништена",
    create_tender_success: "Огласот е пратен за објавување",
    create_offer: "Поднеси понуда",
    offer_error_att:
      "Одбраниот производ не ги задоволува бараните карактеристики",
    offer_att_message:
      "Дозволено е поднесување на понуда само со производи кои ги содржат баранте карактеристики",
    order_success: "Нарачката е прифатена",
    order_no_recieved: "Успешно испратена порака",
    canceled_tenders: "Поништени",
    unsuccessful_delivery_and_terminated_contract:
      "Раскинати/испораки кои не одговараат на барањата и условите од техничката спецификација",
    invalid_delivery_and_contracts: "Раскинати договори и несоодветни испораки",
    total: "Вкупно",
    total_price: "Вкупна цена",
    tender_details: "Детали за набавка",
    autocomplete_error: "Ве молиме внесете уште три карактери",
    priceFrom: "Цена од",
    priceTo: "Цена до",
    no_data_found: "Не се пронајдени записи кои одговараат на критериумите",
    no_matching_data: "Нема пронајдено совпаѓања",
    check_all_eos: "Проверете ги сите економски оператори!",
    tender_budget_estimated_val_err:
      "Вредноста не смее да биде поголема од одлуката за јавна набавка",
    tender_reject_reason: "Изберете причина за поништување на набавката:",
    pending_withdraw: "Чека на поништување",
    tender_before_contract_completion: "Донесена одлука",
    reject_tender_reason: "Причина за поништување на набавката: ",
    contract_realization_user_error:
      "Изберете корисник од листата на лица за реализација на договор",
    terminate_contract_user_role:
      "Договорот може да биде раскинат само од страна на лице за реализација на договор",
    tender_reject_one:
      "настанале непредвидени промени во буџетот на договорниот орган",
    tender_reject_two:
      "понудувачите понудиле цени и услови за извршување на договорот за јавни набавки кои се понеповолни од реалните на пазарот",
    tender_reject_three:
      "оцени дека тендерската документација содржи битни пропусти или недостатоци",
    tender_reject_four:
      "поради непредвидени и објективни околности се промениле потребите на договорниот орган",
    tener_reject_five:
      "не е поднесена ниту една понуда или ниту една прифатлива понуда",
    no_products_found:
      "Не се пронајдени производи кои одговараат на критериумите",
    search_date: "Период за пребарување по датум на објава",
    approve_notification: "Известување",
    approve_tender_message_part_one:
      "Со цел да се подобри системот на е-Пазар и да се избегнат грешки при керирање на набавките, пред да се објави вашата набавка ќе биде испратена на техничка проверка.",
    approve_tender_message_part_two:
      "Одговорот на вашиот предлог за објава на набавка ќе го добиете на ",
    approve_tender_message_part_three:
      ", како и во поштенското сандаче на вашиот профил на е-Пазар.",
    tender_snapshot_approval: "Проверка на набавки",
    tender_snaphot_status: "Статус",
    tender_snapshot_waiting_for_approval: "Чека на одобрување",
    tender_snapshot_preview: "Прегледај набавка",
    date_from: "Од",
    date_to: "До",
    remove_search: "Избриши филтри",
    total_budget: "Проценетата вредност за оваа набавка изнесува: ",
    price_exceeds:
      " ден. што ја надминува вкупната цена од одбраните производи.",
    default_image: "Сликата е основна и неможе да се избрише!",
    image_deleted: "Сликата е успешно избришана",
    image_length: "Дозволени се максимум 5 слики!",
    invalid_product_type: "Документот е прикачен во погрешен тип на производ",
    import_empty_file: "Прикачениот документ е празен",
    accept: "Потврди",
    sign_documents_text:
      "Потребно е да го потпишете извештајот/одлуката од спроведената постапка во менито 'Документи за потпис' и да ја испратите документацијата до одговорното лице",

    view_notification: "Прегледана",
    choose_order_products: "Изберете производи за нарачка",
    choose_delivery_products: "Изберете производи за достава",
    confirm_delivery: "Испорака на нарачка",
    same_as_catalog_price: "Нема промени на цената во каталогот",
    confirm_delivery_text:
      "Избраната нарачка е во согласност со барањата на договорниот орган",
    confirm_order: "Испрати нарачка",
    confirm_order_text:
      "Дали сте сигурни дека сакате да ја испратите нарачката?",
    ca_type: "Тип на договорен орган",
    insert_search_data:
      "Потребно е да внесете минимум еден критериум за пребарување",
    notification_subject: "Предмет",
    notification_sent: "Испратенa на",
    no_notifications: "Не се пронајдени нотификации",
    no_products_for_category: "Не се објавени производи во категоријата",
    sender_mail: "Од",
    receiver_mail: "До",
    message_details: "Податоци за примената порака",
    send_date: "Пратено на",
    mark_read: "Означи за прочитани",
    notification_type: "Тип на порака",
    sent_messages: "Пратени пораки",
    recieved_messages: "Примени пораки",
    message_status: "Статус на порака",
    read_messages: "Прочитани пораки",
    unread_messages: "Непрочитани пораки",
    all_messages: "Сите",
    choose_all: "Изберете понуда за сите ставки!",
    view_doc: "Провери документи",
    message_type: "Тип на порака",
    fileName: "Име на документ",
    IsCrmDocument: "ЦР документ",
    continue: "Продолжи",
    all_are_read: "Сите пораки се означени за прочитани",
    remove_filter: "Исчисти",
    add_brand: "Додади производител",
    brand: "Производител",
    brands: "Производител",
    no_chosen_brands: "Нема избран производител",
    no_product_brands: "Производот нема внесено производител",
    requested_brands: "Барани производители:",
    offered_brand: "Понуден производител:",
    brand_name: "Име на производител",
    view_brands: "Прегледај производители",
    choose_brand: "Одбери производител",
    choose_brands: "Одбери производители",
    brands_info: "Марка или бренд",
    remove_brand_success: "Производителот е успешно отстранет",
    no_brands_for_product_type:
      "Не се пронајдени производители за избраниот тип на производ",
    no_brand: "нема производител",
    create_brend_success: "Успешно креиран производител",
    view_orders: "Прегледај нарачки",
    withdraw_all_offers:
      "Дали сте сигурни дека сакате да ја повлечете понудата?",
    remove_offer: "Отстрани ставка",
    offer_withdraw: "Понудата е успешно повлеченa",
    drag_and_drop_files: 'или користете "Drag & Drop" на документот овде',
    drag_and_drop_images: 'или користете "Drag & Drop" на слика/и овде',
    uploaded_images: "Прикачени слика/и",
    manual_import_mandatory_fields:
      "За полињата: ИМЕ НА ПРОИЗВОД, ОПИС НА ПРОИЗВОД, ЦЕНА, ДДВ и ПРОИЗВОДИТЕЛ задолжително е да се внесе вредност за колони од ексел. Вредноста мора да биде поголема од 0",
    template_columns: "Колони од образец",
    excel_columns: "Колони од ексел",
    column_number: "Реден број ${index}: ",
    validate_document: "Валидирај документ",
    import_document: "Вчитај документ",
    import_validation_errors: "Пронајдени се грешки при валидација:",
    price_column_validation: "Полето Цена мора да биде број",
    upload_import_file_error: "Немате додадено документ за вчитување!",
    ddv_column_validation:
      "Полето ДДВ може да има само една од следните вредности: 0, 5 или 18",
    image_column_validation: "Не е пронајдена слика со име ${imageName}",
    withdraw_offer: "Изберете причина за отстранување на ставката:",
    remove: "Отстрани",
    withdraw_offer_success: "Ставката е успешно отстранета",
    deleteDRD: "Избриши ДРД образец",
    deleteDRD_success: "ДРД образецот е успешно избришан",
    ppb_name: "Министерство за Финансии - Биро за јавни набавки",
    download_document: "Преземи документ",
    download_specifications: "Преземи спецификации",
    public_procurement: "Набавка од мала вредност",
    tender_part_one: "ДОГОВОРЕН ОРГАН",
    tender_part_two: "ПРЕДМЕТ НА НАБАВКАТА",
    tender_subject_part_two: "Предмет на набавката:",
    tender_budget_part_two: "Проценета вредност без ДДВ:",
    tender_nobudget_part_two: "Објавување на проценета вредност:",
    tender_type_part_two: "Вид на договорот за јавна набавка:",
    tender_address_part_two:
      "Место на испорака на стоките или извршување на услугите: ",

    tender_description_part_two:
      " Подетален опис на предметот на договорот за јавна набавка: ",
    small_value_procrement: "Набавки од мала вредност",
    ca_details_part_one: "Податоци за договорниот орган",
    ca_name_part_one: "Назив на договорниот орган:",
    ca_address_part_one: "Адреса:",
    ca_city_part_one: "Град и поштенски код:",
    ca_contact_part_one: "Лице за контакт:",
    active: "Активен",
    search_date_from: "Период за пребарување од",
    request_attributes: "Барани спецификации:",
    offer_attributes: "Понудени спецификации:",
    specification: "СПЕЦИФИКАЦИЈА",
    image_exists: "Сликата е веќе внесена",
    doc_exists: "Документот е веќе внесен",
    case_files: "Досие на постапки",
    catalog_structure: "Структура на каталог",
    product_types_for_category: "Типови на производ за категорија",
    no_attributes_for_product_type:
      "Нема внесено карактеристики за овој тип на производ",
    no_product_types_for_category:
      "Во оваа категорија нема внесено типови на производ",
    evaluation_warning_message:
      "Да се провери дали се соодветни бараните и понудените производители/технички спецификации",
    download_catalog_structure: "Преземи структура",
    case: "Досие",
    tender_documentation: "Тендерска документација",
    decision: "Одлука",
    make_decision: "Донеси одлука",
    mayor: "Одговорно лице",
    add: "Додади",
    submitted_offers: "Поднесени понуди",
    contract_documentation: "Документација од склучени договори",
    full_name: "Име и презиме",
    president: "Претседател",
    document_name: "Назив на документ",
    evaluation_decision: "Одлука од евалуација",
    evaluation_report: " Извештај од евалуација/одлука",
    evaluation: "Евалуација",
    choose_decision_one: "Прифати",
    choose_decision_two: "извештај/одлука ",
    choose_withdraw_one: "Поништи",
    choose_withdraw_two: "постапка",
    sign_decision: "Потпиши одлука",
    sign_report: "Потпиши извештај",
    sign_document_success: "Документот е успешно потпишан",
    sign_document_send: "Документот е успешно испратен до одговорното лице",
    ca_commission: "Комисија на јавни набавки",
    order_delivered_by_eo:
      "Нарачката е испорачана од страна на економскиот оператор",
    delivered_by_eo:
      "Нарачката Е во согласност со барањата на договорниот орган",
    not_delivered_by_eo:
      "Нарачката НЕ Е во согласност со барањата на договорниот орган",
    order_reject_reason: "Образложение",
    order_reject_reason_message: "Внесете образложение за несоодветна испорака",
    orders: "Нарачки",
    product_category_error:
      "Производите во кошничката мора да бидат од категоријата: ",
    no_commission: "Не се пронајдени соодветни записи",
    choose_commission: "Избери членови на комисија",
    tender_commission_text: "Набавката од мала вредност ја спроведува",
    tender_unit: "Лице за јавни набавки",
    commission: "Комисија за јавна набавка",
    view_commission: "Имиња на членови на комисија за јавни набавки",
    contract_realization_order_message:
      "Нарачката е спроведена од страна на лице за реализација на договор",
    tender_commission: "Избери претседател и членови на комисијата",
    tender_budget_error:
      "Проценетата вредност не смее да биде празна или да надминува вредност од",
    tender_budget_min_value: "Проценетата вредност мора да биде поголема од 0",
    add_decision: "Прикачи одлука",
    economic_data: "Податоци за економскиот оператор",
    ca_unit: "Лице за јавни набавки",
    unsuccessfully_delivered_orders:
      "Листа на раскинати договори и несоодветни испораки",
    unsuccessful_delivery: "Несоодветна испорака",

    role_revision: "Ревизија/контрола",

    contract_realization_users: "Лица за реализација на договор",
    add_contract_realization_users: "Додади лица за реализација на договор",
    choose_contract_realization_users: "Избери лица за реализација на договор",
    contract_realization_uers_error:
      "Изберете минимум едно лице за реализација на договор",
    contract_realization_user: "Лице за реализација на договор",
    chosen_contract_realization_users:
      "Имиња на лица за реализација на договор",

    previous_year_annual_plan: "Избравте годишен план од претходната година",

    decision_text:
      "Во овој дел прикачете ја Одлуката за јавна набавка донесена од одговорното лице на вашиот договорен орган за спроведување на постапката. Одлуката треба да биде електронски потпишана со дигиталниот сертификат на вашето одговорно лице или лице овластено од него.",
    not_Installed_pdf_extension_error:
      "За да го потпишете документот ќе мора да ја инсталирате екстензијата од следниот линк:",
    pdf_extension_install_url:
      "https://signingextension.nextsense.com/ExtensionVerify.html?typeExtension=pdf",
    not_Installed_pdf_client_app_error:
      "За да ја потпишете понудата потребно е да ја инасталирате клиентската апликација од следниот линк:",
    notifications: "Нотификации",
    mail_log: "Лог на е-пошта",
    mail_all: "Сите",
    mail_success: "Успешни",
    mail_pending: "На чекање",
    mail_processing: "Процесирање",
    mail_failed: "Неуспешни",
    mail_canceled: "Откажани",
    mail_sender: "Испраќач",
    mail_receiver: "Примач",
    mail_createdAt: "Креирана на",
    mail_sentAt: "Испратена на",
    mail_sentTo: "Испратена до",
    mail_status: "Статус",
    mail_details: "Детали",
    mail_requeued: "Препратени",
    mail_requeue: "Препрати",
    mail_content: "Содржина",
    mail_view_mail_content: "Содржина на е-меил пораката",
    mail_error_log: "Детали за грешката",
    mail_error_details: "Детали за грешка при испраќање",
    mail_subject: "Наслов/Предмет",
    mail_mail_details: "Детали за меил пораката",
    mail_template: "Урнеци за е-пошта",
    no_notifications: "Не се пронајдени нотификации",
    notifications_templates: "Шаблони за е-пошта",
    mail_template_save_success: "Успешно зачуван урнек",
    mail_template_save_failure: "Грешка при зачувување на урнек",
    mail_template_available_params: "Достапни параметри",
    mail_send_test_mail: "Испрати тест е-пошта",
    settings: "Подесувања",
    mail_test_results_info_header: "Резултати од тестот",
    system_settings_props_updated_success: "Подесувањата се успешно ажурирани",
    props: "Параметри",
    key: "Клуч",
    value: "Вредност",
    mail_test_results_info_message:
      "Овде ќе го видите резултатот после тестирањето",
    mail_sent_success_message: "Пораката е успешно испратена",
    success: "Успешно",
    test_failure: "Неуспешно",
    reason: "Причина",
    test_mail_server_header: "Тестирање на е-меил серверот",
    test_mail_server_subheader:
      "Во овој дел можете да го тестирате е-меил серверот",
    test_mail_server_description:
      "Моментално се подесени следните SMTP параметри",
    email_address: "Е-меил адреса",
    send_test_mail: "Испрати",
    user_your_own_email_address: "Користи ја само својата е-меил адреса",
    for_testing_purposes_only:
      "Ова функционалност треба да се користи исклучиво за тестирање на е-меил (SMTP) серверот",
    SUBMITTED: "ПОДНЕСЕНО",
    APPROVED: "ОДОБРЕНО",
    REJECTED: "ОДБИЕНО",
    PENDING: "ЗА ОБРАБОТКА",
    PROCESSING: "ВО ПРОЦЕС НА ОБРАБОТКА",
    DONE: "ЗАВРШЕНО",
    FAILED: "НЕУСПЕШНО",
    CANCELED: "ОТКАЖАНО",
    SUCCESS: "УСПЕШНО",

    //STATUSES
    status_published: "Набавката е објавена",
    status_created: "Се чека за објавување",
    status_for_evaluation: "Евалуацијата може да започне",
    status_evaluation: "Евалуацијата е започната",
    status_to_be_signed: "Се чека да се потпише извештајот",
    status_signed_by_president:
      "Се чека да се испрати документација до одговорно лице",
    status_for_approval: "Се чека да биде донесена одлука",
    status_cancel: "Поништена набавка",
    status_to_be_canceled: "Постапката чека на поништување",
    status_completed: "Одлуката е јавно објавена",
    deactivate_product_error_message:
      "Внесете вредност во сите наведени полиња",
    status_decision_to_withdraw:
      "Одлуката е јавно објавена (поништена постапка)",

    appeal_document: "Жалба",
    additional_appeal_documents: "Прилози",
    eo_request_for_additional_documents:
      "Барање за дополнување на документи до ЕО",
    ca_request_for_additional_documents:
      "Барање за дополнување на документи до ДО",

    productName: "Име на производ",
    productDescription: "Опис на производ",
    productPrice: "Единечна цена",
    productDdv: "ДДВ",
    productImage: "Име на слика",
  },
  en: {
    no_products_found:
      "Не се пронајдени производи кои одговараат на критериумите",
    create_offer_success: "Понудата е успешно поднесена",
    entity_delete_confirmation_text: "Дали сте сигурни",
    prod_description: "Подетален опис на производот",
    create_catalog_success: "Каталогот е успешно креиран",
    tender_details: "Детали за набавка",
    dashboard: "Работна табла",
    users: "Корисници",
    attributes: "Карактеристики",
    my_catalog: "Мој Каталог",
    generate_offer: "Креирај понуда",
    my_offer: "Мои понуди",
    ddv: "ДДВ",
    appeal: "Жалби",
    procedure_type: "Вид на постапка",
    procedure_status: "Статус на постапката",
    appeal_status: "Статус на жалба",
    create_appeal: "Креирај жалба",
    edit_appeal: "Едитирај жалба",
    send: "Испрати",
    procedure_details: "Податоци за постапката",
    resource: "Извор на средства",
    procedure_date: "Датум на постапка",
    additional_information: "Дополнителни информации",
    appeal_reason: "Основи за жалба",
    document_type: "Тип на документ",
    document: "Документ",
    insert_doc: "Прикачи",
    payment_proof: "Доказ за извршена уплата",
    additional: "Прилози",
    e_Appeals: "Е-Жалби",
    tender_publish_pending: "Набавката е испратена на објава",
    restrict_publishing: "Забрана за објава",
    restrict_product_publishing: "Забрана за објава на производ",
    restrict_product_confirmation_message:
      "Дали сте сигурни дека сакате да го означите производот [CatalogProductName] како невалиден?",
    restrict_product_success_message: "Производот е означен како невалиден",
    invalid_product_message:
      "Производот не е валиден на системот на е-Пазар и истиот не може да биде објавен",
    view_appeal: "Прегледај жалба",
    appeal_details: "Детали за жалба",
    publish_selected: "Објави ги селектираните",
    unpublish_selected: "Повлечи ги селектираните",
    phone_number: "Телефонски број",
    embg: "ЕМБГ или 13 цифри",
    mobile_number: "Мобилен телефон",
    profile_documents: "Документи на профил",
    doc_message:
      "Економскиот оператор е одговорен за ажурирање на документите преку овој панел, како и за валидноста на прикачените документи!",
    upload_drd: "Прикачи ДРД",
    upload_xlsx: "Прикачи каталог",
    edit_doc: "Ажурирај документи",

    login_h1: "Биро за јавни набавки",
    login_h2: "Е-Пазар",
    login_signin: "Најави се",
    login_signup: "Регистрирај се",
    login_signup_link: "Немаш корисничка сметка?",
    login_forgot_password: "Промени лозинка",
    login_forgot_password_link: "Заборавена лозинка?",
    login_username: "Корисничко име",
    login_password: "Лозинка",
    login_email: "Е-пошта",
    login_firstname: "Име",
    login_lastname: "Презиме",
    login_confirm_password: "Потврди лозинка",
    login_submit: "Потврди",
    login_createuser_success: "Успешно регистриран корисник: ",
    login_fail: "Погрешно корисничко име или лозинка",
    required_field: " е задолжително поле",
    logout: "Одјави се",
    my_profile: "Мој профил",

    category_name: "Име на категорија",
    category_code: "Код на категорија",
    subcategory_name: "Име на подкатегорија",
    subcategory_code: "Код на подкатегорија",
    category_active: "Активна категорија",
    category_not_active: "Неактивна категорија",
    subcategory_active: "Активна подкатегорија",
    subcategory_not_active: "Неактивна подкатегорија",
    date_creted: "Датум на креирање",
    hierarchy_level: "Хиерархиско ниво",
    actions: "Акции",
    add_subcategory: "Додади подкатегорија",
    edit: "Промени",
    delete: "Избриши",
    details: "Детали",
    add_category: "Додади категорија",
    save: "Зачувај",
    choose_category: "Одберете категорија",
    edit_category: "Промени категорија",
    show_documents: "Прикажи",

    create_category_success: "Успешно креирана категорија",
    create_subcategory_success: "Успешно креирана подкатегорија",
    delete_category_success: "Успешно избришана категорија",
    edit_category_success: "Успешно изменета категорија",
    create_product_type_success: "Успешно креиран тип на производ",
    delete_product_type_success: "Успешно избришан тип на производ",
    edit_product_type_success: "Успешно изменет тип на производ",
    create_attribute_success: "Успешно креиран атрибут",
    delete_attribute_success: "Успешно избришан атрибут",
    edit_attribute_success: "Успешно изменет атрибут",
    search_product_type: "Пребарај тип на производ",
    create_product_success: "Успешно креиран производ",
    update_product_success: "Успешно е ажуриран производот",
    delete_product_success: "Успешно избришан производ",

    eMarket: "Е-Пазар",
    footerTrade: "© 2020 - Биро за јавни набавки. Сите права задржани",
    footerText:
      "Корисниците се одговорни за веродостојноста и точноста на внесените податоци",
    electronicSystem: "Електронски систем за јавни набавки",
    smallPP: "е-Пазар",
    e_catalogue: "Е-Каталог",
    activeTenders: "Активни набавки од мала вредност",
    finishedTenders: "Завршени набавки од мала вредност",
    home: "Почетна",
    categories: "Категории",
    category: "Категорија",
    availability: "Достапност",
    average_price: "Просечна цена",
    cpv: "CPV",
    measure_unit: "Мерна единица",
    add_to_basket: "Додади во кошничка",
    product_details: "ДЕТАЛИ ЗА ПРОИЗВОД",
    requirement_details: "Детали за ",
    close: "Затвори",
    search: "Пребарувај",
    active_tenders: "Активни набавки од мала вредност",
    finished_tenders: "Завршени набавки од мала вредност",
    tender_number: "Број",
    procurement_number: "Број на набавка",
    contracting_authority: "Договорен орган",
    tender_subject: "Предмет на набавката",
    tender_type: "Вид на набавката",
    start_date: "Датум на објава",
    end_date: "Краен рок",
    tenders_per_page: "Oгласи по страна:",
    economic_operator: "Економски оператор",
    subcategories: "Подкатегории",
    rows_per_page: "Редови по страница",
    chosen_category: "Одбрана категорија: ",
    delete_category: "Избриши категорија",

    create_tender: "Креирај набавка",
    publish_tender: "Објави набавка",
    basic_data: " Основни податоци за набавката",
    shopping_cart: "Купувачка кошничка",
    tender_name: "Предмет на огласот за јавна набавка",
    tender_description: "Подетален опис на набавката",
    tender_administration: "Административни информации",
    tender_submission_date: "Краен рок за достава на понуди",
    tender_validity: "Период на важност на понудата во денови",
    tender_question_date: "Краен рок за поставување прашања",
    tender_answer_date: "Краен рок за одговор на прашања",
    tender_agreementExpectedDuration: "Период во месеци",
    annual_plans: "Одбери набавка од годишен план",
    estimated_value: "Проценета вредност",
    public_value: "Јавна",
    type_of_tender: "Вид на набавката",
    contract_duration: "Времетраење на договорот",
    invalid_contract: "Невалиден договор",
    genetal_terms_of_contract: "Општи услови на договорот",
    place_of_delivery: "Место на испорака ",
    delivery_date: "Датум на испорака",
    payment_terms: "Услови на плаќање",
    guarantee_period: "Гарантен период",
    award_criteria: "Критериум за доделување на договор",
    product_quantity: "Количина",
    generate_pdf: "Прегледај PDF",
    add_documents: "Прикачи документи",
    documents: "Документи",
    chosen_products: "Избрани производи",
    empty_cart: "Купувачката кошничка е празна",
    details_product: "Детали за производот",
    lowest_price: "Најниска цена",
    product_no_atributes: "Производот нема дополнителни карактеристики",

    view_offers: "Прегледај понуди",
    choose_offer: "Избери понуда",
    view_decision: "Прегледај одлука",
    view_eo: "Прегледај EO",

    choose_products: "Избери производи",
    chooose_product: "Избери производ",
    search_products: "Пребарај производи",
    add_price: "Дефиницирај единечна цена",
    review_offer: "Прегледај понуда",

    order_delivery: "Испорака на нарачки",
    active_procurements: "Активни набавки",
    all_procurements: "Сите набавки",
    review_tender: "Преглед на набавката",

    product_type: "Тип на производ",
    add_product_type: "Додади тип на производ",
    product_type_name: "Име",
    product_type_code: "Код",
    add_attributes: "Додади карактеристики",
    delete_product_type: "Избриши тип на производ?",
    choose_product_type: "Избери тип на производ",
    chosen_product_type: "Избран тип на производ:",
    chosen_attribute: "Избрана карактеристика:",
    product_type_description: "Опис на тип на производ",
    edit_product_type: "Промени тип на производ",
    mandatory_attribute: "Задолжителени карактеристики",
    edit_attribute: "Промени карактеристики",
    attribute_name: "Име на атрибут",
    delete_attribute: "Избриши атрибут",
    custom_attribute: "Произволна вредност",
    add_value: "Додади вредност",
    no_products: "Нема производи за избраната категорија",
    attribute_value: "Вредност на атрибут",
    attribute_values: "Вредности на атрибут",
    value_exists: "Внесовте веќе постоечка вредност",
    attribute_required: "Задолжително поле",
    all_categories: "Сите категории",
    search_categories: "Пребарај категории",
    pdf_length: "Дозволен е еден документ",
    create_pdf_success: "Успешно додаден документ",

    char_number: "Полето мора да содржи 10 карактери",
    cancel: "Откажи",
    publishAll: "Oбјави ги сите",
    unpublishAll: "Повлечи ги сите",
    publish: "Објави",
    unpublish: "Повлечи",

    accept_offer: "Прифати",
    reject_offer: "Отфрли",
    add_comment: "Додади коментар",
    reject_reason: "Причина за одбивање на понудата",

    create: "Креирај",
    info: "Информација",
    catalogMessage: "Немате креирано ваш каталог —",
    catalogMessage1: "со клик на копчето можете да креирате!",
    tenderMessage:
      "За дадените карактеристики се предложени вредности од избраниот производ и истите може да се променат!",
    tenderMessage1: "Набавката ќе биде објавена со следниве датуми!",
    catalog_name: "Име на каталог",
    catalog_description: "Опис на каталог",
    add_catalog: "Додади каталог",
    catalog_active: "Активен каталог",
    catalog_not_active: "Неактивен каталог",
    catalog_picture: "Слика",
    catalog_product_name: "Име на производ",
    catalog_product_price: "Цена",
    add_catalog_product: "Додади производ",
    delete_product: "Избриши производ",
    edit_product: "Промени производ",
    chosen_product: "Избран производ",
    chosen_attributes: "Избрани карактеристики",
    import_catalog_product: "Вчитај производи",
    no_products_added: "Немате додадено производи!",
    product_basic_data: "Основни податоци за производ",
    product_attribute: "Карактеристики на производ",
    product_attribute_value: "Детална спецификација",
    insert_attribute_value: "Внесете детална спецификација",
    choose_attribute_value: "Изберете вредност",
    product_name: "Име на производ",
    remove_from_cart: "Производот е отстранет од кошничка",
    product_description: "Опис на производ",
    product_price: "Единечна цена",
    product_price_ddv: "Единечна цена со ДДВ",
    product_image: "Додади слика/и",
    mandatory_attributes: "Задолжителни карактеристики",
    not_mandatory_attribute: "Незадолжителни карактеристики",
    next: "Следно",
    back: "Претходно",
    create_order: "Креирај нарачка",
    order: "Нарачај",
    networkError: "Проблем со конекција!",
    uploadImage:
      "Прикачени слики (селектираната слика ќе биде додадена прва во каталогот)",
    cа_name: "Назив на договорниот орган",
    cа_addres: "Адреса",
    cа_city: "Град",
    cа_postcode: "Поштенски код",
    cа_mail: "Интернет адреса",
    ca_contactName: "Лице за контакт",
    ca_contactMail: "Е-маил",
    ca_contactPhone: "Телефон/Факс",
    ca_tender_subject: "Предмет на набавката",
    invalid_email_address_format: "Форматот на меил адресата е невалиден",
    must_be_number: "Вредноста мора да биде број",
    no_offer: "Нема активни огласи!",
    offer_brand_warning:
      "Поднесувате понуда без производител за ставка каде се бара одреден производител",
    offers_report: "Извештај од понудувачи",
    offer_accept_success: "Избрана е понуда со минимална цена",
    offer_reject_success: "Понудата е одбиена!",
    offer_choosen_success: "Понудата е прифатена!",
    product_publish: "Објавени",
    product_unpublish: "Необјавени",
    products: "Производи",
    save_change: "Податоците се успешно зачувани",
    tender_status: "Статус на тендери",
    complete: "Завршени",
    notcomplete: "Тековни",
    choose_mesure_unit: "Одбери мерна единица",
    mesure_unit: "Мерна единица",
    no_unpublished: "Нема необјавени производи",
    no_published: "Нема објавени производи",
    no_tenders: "Нема активни набавки",
    min_price: "Понудениот производ е со најниска цена",
    my_profile: "Мој профил",
    personal_data: "Лични податоци",
    notifications: "Нотификации",
    status: "Статус",
    mail_log: "Лог на е-пошта",
    mail_all: "Сите",
    mail_success: "Успешни",
    mail_pending: "На чекање",
    mail_processing: "Процесирање",
    mail_failed: "Неуспешни",
    mail_canceled: "Откажани",
    mail_sender: "Испраќач",
    mail_receiver: "Примач",
    mail_createdAt: "Креирана на",
    mail_sentAt: "Испратена на",
    mail_status: "Статус",
    mail_details: "Детали",
    mail_requeued: "Препратени",
    mail_requeue: "Препрати",
    mail_content: "Содржина",
    mail_view_mail_content: "Содржина на е-меил пораката",
    mail_error_log: "Детали за грешката",
    mail_error_details: "Детали за грешка при испраќање",
    mail_subject: "Наслов/Предмет",
    mail_mail_details: "Детали за меил пораката",
    mail_template: "Урнеци за е-пошта",
    no_notifications: "Не се пронајдени нотификации",
    notifications_templates: "Шаблони за е-пошта",
    mail_template_save_success: "Успешно зачуван урнек",
    mail_template_save_failure: "Грешка при зачувување на урнек",
    mail_template_available_params: "Достапни параметри",
    mail_send_test_mail: "Испрати тест е-пошта",
    settings: "Подесувања",
    mail_test_results_info_header: "Резултати од тестот",
    system_settings_props_updated_success: "Подесувањата се успешно ажурирани",
    props: "Параметри",
    key: "Клуч",
    value: "Вредност",
    mail_test_results_info_message:
      "Овде ќе го видите резултатот после тестирањето",
    mail_sent_success_message: "Пораката е успешно испратена",
    success: "Успешно",
    test_failure: "Неуспешно",
    reason: "Причина",
    test_mail_server_header: "Тестирање на е-меил серверот",
    test_mail_server_subheader:
      "Во овој дел можете да го тестирате е-меил серверот",
    test_mail_server_description:
      "Моментално се подесени следните SMTP параметри",
    email_address: "Е-меил адреса",
    send_test_mail: "Испрати",
    user_your_own_email_address: "Користи ја само својата е-меил адреса",
    for_testing_purposes_only:
      "Ова функционалност треба да се користи исклучиво за тестирање на е-меил (SMTP) серверот",
    SUBMITTED: "ПОДНЕСЕНО",
    APPROVED: "ОДОБРЕНО",
    REJECTED: "ОДБИЕНО",
    PENDING: "ЗА ОБРАБОТКА",
    PROCESSING: "ВО ПРОЦЕС НА ОБРАБОТКА",
    DONE: "ЗАВРШЕНО",
    FAILED: "НЕУСПЕШНО",
    CANCELED: "ОТКАЖАНО",
    SUCCESS: "УСПЕШНО",
  },
  al: {
    no_products_found:
      "Не се пронајдени производи кои одговараат на критериумите",
    create_offer_success: "Понудата е успешно поднесена",
    entity_delete_confirmation_text: "Дали сте сигурни",
    prod_description: "Подетален опис на производот",
    create_catalog_success: "Каталогот е успешно креиран",
    tender_details: "Детали за набавка",
    dashboard: "Работна табла",
    users: "Корисници",
    attributes: "Карактеристики",
    my_catalog: "Мој Каталог",
    generate_offer: "Креирај понуда",
    my_offer: "Мои понуди",
    ddv: "ДДВ",
    appeal: "Жалби",
    procedure_type: "Вид на постапка",
    procedure_status: "Статус на постапката",
    appeal_status: "Статус на жалба",
    create_appeal: "Креирај жалба",
    edit_appeal: "Едитирај жалба",
    send: "Испрати",
    procedure_details: "Податоци за постапката",
    resource: "Извор на средства",
    procedure_date: "Датум на постапка",
    additional_information: "Дополнителни информации",
    appeal_reason: "Основи за жалба",
    document_type: "Тип на документ",
    document: "Документ",
    insert_doc: "Прикачи",
    payment_proof: "Доказ за извршена уплата",
    additional: "Прилози",
    e_Appeals: "Е-Жалби",
    view_appeal: "Прегледај жалба",
    appeal_details: "Детали за жалба",
    publish_selected: "Објави ги селектираните",
    unpublish_selected: "Повлечи ги селектираните",

    phone_number: "Телефонски број",
    embg: "ЕМБГ или 13 цифри",
    mobile_number: "Мобилен телефон",
    profile_documents: "Документи на профил",
    doc_message:
      "Економскиот оператор е одговорен за ажурирање на документите преку овој панел, како и за валидноста на прикачените документи!",
    upload_drd: "Прикачи ДРД",
    edit_doc: "Ажурирај документи",

    login_h1: "Биро за јавни набавки",
    login_h2: "Е-Пазар",
    login_signin: "Најави се",
    login_signup: "Регистрирај се",
    login_signup_link: "Немаш корисничка сметка?",
    login_forgot_password: "Промени лозинка",
    login_forgot_password_link: "Заборавена лозинка?",
    login_username: "Корисничко име",
    login_password: "Лозинка",
    login_email: "Е-пошта",
    login_firstname: "Име",
    login_lastname: "Презиме",
    login_confirm_password: "Потврди лозинка",
    login_submit: "Потврди",
    login_createuser_success: "Успешно регистриран корисник: ",
    login_fail: "Погрешно корисничко име или лозинка",
    required_field: " е задолжително поле",
    logout: "Одјави се",
    my_profile: "Мој профил",

    category_name: "Име на категорија",
    category_code: "Код на категорија",
    subcategory_name: "Име на подкатегорија",
    subcategory_code: "Код на подкатегорија",
    category_active: "Активна категорија",
    category_not_active: "Неактивна категорија",
    subcategory_active: "Активна подкатегорија",
    subcategory_not_active: "Неактивна подкатегорија",
    date_creted: "Датум на креирање",
    hierarchy_level: "Хиерархиско ниво",
    actions: "Акции",
    add_subcategory: "Додади подкатегорија",
    edit: "Промени",
    delete: "Избриши",
    details: "Детали",
    add_category: "Додади категорија",
    save: "Зачувај",
    choose_category: "Одберете категорија",
    edit_category: "Промени категорија",
    show_documents: "Прикажи",

    create_category_success: "Успешно креирана категорија",
    create_subcategory_success: "Успешно креирана подкатегорија",
    delete_category_success: "Успешно избришана категорија",
    edit_category_success: "Успешно изменета категорија",
    create_product_type_success: "Успешно креиран тип на производ",
    delete_product_type_success: "Успешно избришан тип на производ",
    edit_product_type_success: "Успешно изменет тип на производ",
    create_attribute_success: "Успешно креиран атрибут",
    delete_attribute_success: "Успешно избришан атрибут",
    edit_attribute_success: "Успешно изменет атрибут",
    search_product_type: "Пребарај тип на производ",
    create_product_success: "Успешно креиран производ",
    update_product_success: "Успешно е ажуриран производот",
    delete_product_success: "Успешно избришан производ",

    eMarket: "Е-Пазар",
    footerTrade: "© 2020 - Биро за јавни набавки. Сите права задржани",
    footerText:
      "Корисниците се одговорни за веродостојноста и точноста на внесените податоци ",
    electronicSystem: "Електронски систем за јавни набавки",
    smallPP: "е-Пазар",
    e_catalogue: "Е-Каталог",
    activeTenders: "Активни набавки од мала вредност",
    finishedTenders: "Завршени набавки од мала вредност",
    home: "Почетна",
    categories: "Категории",
    category: "Категорија",
    availability: "Достапност",
    average_price: "Просечна цена",
    cpv: "CPV",
    measure_unit: "Мерна единица",
    add_to_basket: "Додади во кошничка",
    product_details: "ДЕТАЛИ ЗА ПРОИЗВОД",
    requirement_details: "Детали за ",
    close: "Затвори",
    search: "Пребарувај",
    active_tenders: "Активни набавки од мала вредност",
    finished_tenders: "Завршени набавки од мала вредност",
    tender_number: "Број",
    contracting_authority: "Договорен орган",
    tender_subject: "Предмет на набавката",
    tender_type: "Вид на набавката",
    start_date: "Датум на објава",
    end_date: "Краен рок",
    tenders_per_page: "Oгласи по страна:",
    economic_operator: "Економски оператор",
    subcategories: "Подкатегории",
    rows_per_page: "Редови по страница",
    chosen_category: "Одбрана категорија: ",
    delete_category: "Избриши категорија",

    create_tender: "Креирај набавка",
    publish_tender: "Објави набавка",
    basic_data: "Основни податоци за набавката",
    shopping_cart: "Купувачка кошничка",
    tender_name: "Предмет на огласот за јавна набавка",
    tender_description: "Подетален опис на набавката",
    tender_administration: "Административни информации",
    tender_submission_date: "Краен рок за достава на понуди",
    tender_validity: "Период на важност на понудата во денови",
    tender_question_date: "Краен рок за поставување прашања",
    tender_answer_date: "Краен рок за одговор на прашања",
    tender_agreementExpectedDuration: "Период во месеци",
    annual_plans: "Годишни планови за набавки од мала вредност",
    estimated_value: "Проценета вредност",
    public_value: "Јавна",
    type_of_tender: "Вид на набавката",
    contract_duration: "Времетраење на договорот",
    genetal_terms_of_contract: "Општи услови на договорот",
    place_of_delivery: "Место на испорака ",
    delivery_date: "Датум на испорака",
    payment_terms: "Услови на плаќање",
    guarantee_period: "Гарантен период",
    award_criteria: "Критериум за доделување на договор",
    product_quantity: "Количина",
    generate_pdf: "Прегледај PDF",
    add_documents: "Прикачи документи",
    documents: "Документи",
    chosen_products: "Избрани производи",
    empty_cart: "Купувачката кошничка е празна",
    details_product: "Детали за производот",
    lowest_price: "Најниска цена",
    product_no_atributes: "Производот нема дополнителни карактеристики",

    view_offers: "Прегледај понуди",
    choose_offer: "Избери понуда",
    view_decision: "Прегледај одлука",
    view_eo: "Прегледај EO",

    choose_products: "Избери производи",
    chooose_product: "Избери производ",
    search_products: "Пребарај производи",
    add_price: "Дефиницирај единечна цена",
    review_offer: "Прегледај понуда",

    order_delivery: "Испорака на нарачки",
    active_procurements: "Активни набавки",
    all_procurements: "Сите набавки",
    review_tender: "Преглед на набавката",

    product_type: "Тип на производ",
    add_product_type: "Додади тип на производ",
    product_type_name: "Име",
    product_type_code: "Код",
    add_attributes: "Додади карактеристики",
    delete_product_type: "Избриши тип на производ?",
    choose_product_type: "Избери тип на производ",
    chosen_product_type: "Избран тип на производ:",
    chosen_attribute: "Избрана карактеристика:",
    product_type_description: "Опис на тип на производ",
    edit_product_type: "Промени тип на производ",
    mandatory_attribute: "Задолжителени карактеристики",
    edit_attribute: "Промени карактеристики",
    attribute_name: "Име на атрибут",
    delete_attribute: "Избриши атрибут",
    custom_attribute: "Произволна вредност",
    add_value: "Додади вредност",
    no_products: "Нема производи за избраната категорија",
    attribute_value: "Вредност на атрибут",
    attribute_values: "Вредности на атрибут",
    value_exists: "Внесовте веќе постоечка вредност",
    attribute_required: "Задолжително поле",
    all_categories: "Сите категории",
    search_categories: "Пребарај категории",
    pdf_length: "Дозволен е еден документ",
    create_pdf_success: "Успешно додаден документ",

    char_number: "Полето мора да содржи 10 карактери",
    cancel: "Откажи",
    publishAll: "Oбјави ги сите",
    unpublishAll: "Повлечи ги сите",
    publish: "Објави",
    unpublish: "Повлечи",

    accept_offer: "Прифати",
    reject_offer: "Одбиј",
    add_comment: "Додади коментар",
    reject_reason: "Причина за одбивање на понудата",

    create: "Креирај",
    info: "Информација",
    catalogMessage: "Немате креирано ваш каталог —",
    catalogMessage1: "со клик на копчето можете да креирате!",
    tenderMessage:
      "За дадените карактеристики се предложени вредности од избраниот производ и истите може да се променат!",
    choose_brand_message:
      "Можете да изберете еден или повеќе од дадените производители за бараниот производ или пак да внесете нови производители.",
    tenderMessage1: "Набавката ќе биде објавена со следниве датуми!",
    catalog_name: "Име на каталог",
    catalog_description: "Опис на каталог",
    add_catalog: "Додади каталог",
    catalog_active: "Активен каталог",
    catalog_not_active: "Неактивен каталог",
    catalog_picture: "Слика",
    catalog_product_name: "Име на производ",
    catalog_product_price: "Цена",
    add_catalog_product: "Додади производ",
    delete_product: "Избриши производ",
    edit_product: "Промени производ",
    chosen_product: "Избран производ",
    chosen_attributes: "Избрани карактеристики",
    import_catalog_product: "Вчитај производ",
    no_products_added: "Немате додадено производи!",
    product_basic_data: "Основни податоци за производ",
    product_attribute: "Карактеристики на производ",
    product_attribute_value: "Детална спецификација",
    insert_attribute_value: "Внесете детална спецификација",
    choose_attribute_value: "Изберете вредност",
    product_name: "Име на производ",
    remove_from_cart: "Производот е отстранет од кошничка",
    product_description: "Опис на производ",
    product_price: "Единечна цена",
    product_price_ddv: "Единечна цена со ДДВ",
    product_image: "Додади слика/и",
    mandatory_attributes: "Задолжителни карактеристики",
    not_mandatory_attribute: "Незадолжителни карактеристики",
    next: "Следно",
    back: "Претходно",
    create_order: "Креирај нарачка",
    order: "Нарачај",
    networkError: "Проблем со конекција!",
    uploadImage:
      "Прикачени слики (селектираната слика ќе биде додадена прва во каталогот)",
    cа_name: "Назив на договорниот орган",
    cа_addres: "Адреса",
    cа_city: "Град",
    cа_postcode: "Поштенски код",
    cа_mail: "Интернет адреса",
    ca_contactName: "Лице за контакт",
    ca_contactMail: "Е-маил",
    ca_contactPhone: "Телефон/Факс",
    ca_tender_subject: "Предмет на набавката",
    invalid_email_address_format: "Форматот на меил адресата е невалиден",
    must_be_number: "Вредноста мора да биде број",
    no_offer: "Нема активни огласи!",
    offer_accept_success: "Избрана е понуда со минимална цена",
    offer_reject_success: "Понудата е одбиена!",
    offer_choosen_success: "Понудата е прифатена!",
    product_publish: "Објавени",
    product_unpublish: "Необјавени",
    products: "Производи",
    save_change: "Податоците се успешно зачувани",
    tender_status: "Статус на тендери",
    complete: "Завршени",
    notcomplete: "Тековни",
    choose_mesure_unit: "Одбери мерна единица",
    mesure_unit: "Мерна единица",
    no_unpublished: "Нема необјавени производи",
    no_published: "Нема објавени производи",
    no_tenders: "Нема активни набавки",
    min_price: "Понудениот производ е со најниска цена",
    personal_data: "Лични податоци",
    notifications: "Нотификации",
    mail_log: "Лог на е-пошта",
    mail_all: "Сите",
    mail_success: "Успешни",
    mail_pending: "На чекање",
    mail_processing: "Процесирање",
    mail_failed: "Неуспешни",
    mail_canceled: "Откажани",
    mail_sender: "Испраќач",
    mail_receiver: "Примач",
    mail_createdAt: "Креирана на",
    mail_sentAt: "Испратена на",
    mail_status: "Статус",
    mail_details: "Детали",
    mail_requeued: "Препратени",
    mail_requeue: "Препрати",
    mail_content: "Содржина",
    mail_view_mail_content: "Содржина на е-меил пораката",
    mail_error_log: "Детали за грешката",
    mail_error_details: "Детали за грешка при испраќање",
    mail_subject: "Наслов/Предмет",
    mail_mail_details: "Детали за меил пораката",
    mail_template: "Урнеци за е-пошта",
    no_notifications: "Не се пронајдени нотификации",
    notifications_templates: "Шаблони за е-пошта",
    mail_template_save_success: "Успешно зачуван урнек",
    mail_template_save_failure: "Грешка при зачувување на урнек",
    mail_template_available_params: "Достапни параметри",
    mail_send_test_mail: "Испрати тест е-пошта",
    settings: "Подесувања",
    mail_test_results_info_header: "Резултати од тестот",
    system_settings_props_updated_success: "Подесувањата се успешно ажурирани",
    props: "Параметри",
    key: "Клуч",
    value: "Вредност",
    mail_test_results_info_message:
      "Овде ќе го видите резултатот после тестирањето",
    mail_sent_success_message: "Пораката е успешно испратена",
    success: "Успешно",
    test_failure: "Неуспешно",
    reason: "Причина",
    test_mail_server_header: "Тестирање на е-меил серверот",
    test_mail_server_subheader:
      "Во овој дел можете да го тестирате е-меил серверот",
    test_mail_server_description:
      "Моментално се подесени следните SMTP параметри",
    email_address: "Е-меил адреса",
    send_test_mail: "Испрати",
    user_your_own_email_address: "Користи ја само својата е-меил адреса",
    for_testing_purposes_only:
      "Ова функционалност треба да се користи исклучиво за тестирање на е-меил (SMTP) серверот",
    SUBMITTED: "ПОДНЕСЕНО",
    APPROVED: "ОДОБРЕНО",
    REJECTED: "ОДБИЕНО",
    PENDING: "ЗА ОБРАБОТКА",
    PROCESSING: "ВО ПРОЦЕС НА ОБРАБОТКА",
    DONE: "ЗАВРШЕНО",
    FAILED: "НЕУСПЕШНО",
    CANCELED: "ОТКАЖАНО",
    SUCCESS: "УСПЕШНО",
  },
});

if (window.localStorage.getItem("locale") == null) {
  window.localStorage.setItem("locale", "mk");
  LOCALE.setLanguage(window.localStorage.getItem("locale"));
} else {
  LOCALE.setLanguage(window.localStorage.getItem("locale"));
}

export const changeLanguage = (languageCode) => {
  window.localStorage.setItem("locale", languageCode);
  window.location.reload();
};
